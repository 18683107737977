<template>
   <div>
      <header>
         <div class="container">
            <div class="row">
               <div class="col-6 col-sm-4 col-xl-3 col-logo">
                  <a :href='"/" + flagSelected + "/#home"' class="logo">
                     <img src="~@/assets/images/header/logo-indice-avancado.svg" alt="Índice Avançado">
                  </a>
               </div>

               <div class="col-6 col-sm-8 col-xl-9 col-lang">
                  <div class="row-master">
                     <div class="col-menu">
                        <MenuPlatform />
                     </div>

                     <div class="col-lang-login">
                        <SelectLanguage />
                        <Login />
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </header>

      <div class="menuMobile" id="menuPlatformMobile">
         <MenuPlatform />
      </div>
   </div>
</template>


<script>
   const $ = require('jquery')
   window.$ = $

   $(document).ready(function(){
      var animaHeader = $('body');
      $(window).scroll(function () {
         if ($(this).scrollTop() > 40) {
            animaHeader.addClass("headerFixed");
         } else {
            animaHeader.removeClass("headerFixed");
         }
      });
   });


   import SelectLanguage from '@/components/SelectLanguage.vue';
   import MenuPlatform from '@/components/MenuPlatform.vue';
   import Login from '@/components/Login.vue';

   export default {
      components: {
         SelectLanguage,
         MenuPlatform,
         Login
      },

      data (){
         return {
            flagSelected: "en"
         }
      },

      async mounted(){
         this.flagUrl()
      },

      methods: {
         flagUrl(){
            const flagSelect = String(window.location.pathname).split("/")[1];
            this.flagSelected = flagSelect
         },
      }
   }
</script>

<style src="@/assets/scss/_header.scss" lang="scss" scoped />