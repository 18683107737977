<template>
    <CookieConsent
        :message= texts.message
        :link-label= texts.linkLabel
        :buttonLabel= texts.btnLabel
        :href= urlPrivacy
    />
</template>

<script>
    import CookieConsent from 'vue-cookieconsent-component/src/components/CookieConsent.vue'
    import getMyFavouriteLanguage from "@/services/language.js";

    export default {
        components: {
        CookieConsent
        },

        data (){
            return{
                flagSelected: "en",
                urlPrivacy: '',
                texts: {
                    message: '',
                    linkLabel: '',
                    btnLabel: ''
                }
            }
        },

        async mounted(){
            this.flagUrl()
        },

        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
            
            privacyUrl(){
                this.urlPrivacy = this.flagSelected + "/privacy"
            },

            getMyLanguage(group, code){
               return getMyFavouriteLanguage(group, code)
            },

            cookieTexts(){
                let textsMessage = this.getMyLanguage("cookie-consent", "cookie.consent.message")
                let textsLinkLabel = this.getMyLanguage("cookie-consent", "cookie.consent.link.label")
                let textsBtnLabel = this.getMyLanguage("cookie-consent", "cookie.consent.btn.label")

                this.texts.message = textsMessage
                this.texts.linkLabel = textsLinkLabel
                this.texts.btnLabel = textsBtnLabel
            }
        },

        created() {
            this.privacyUrl();
            this.cookieTexts()
        }
    }
</script>


<style src="@/assets/scss/_cookie-consent.scss" lang="scss" />