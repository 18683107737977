<template>
    <router-view/>  
</template>

<script>
    import getMyFavouriteLanguage from "@/services/language.js";
    
    export default {
        components: {
            
        },
        data: function () {
            return {
                flagSelected: 'pt',
            }
        },
        
        beforeMount(){
            this.flagUrl()
        },

        async mounted(){
            
        },

        methods: {
            flagUrl(){
                let code = String(window.location.pathname).split("/")[1];
                
                if ( !code ) {
                    code = 'pt'
                } else{
                    this.flagSelected = code
                }
            },

            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            }
        }
    }
</script>

<style src="@/assets/scss/_custom-app.scss" lang="scss" />