import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home/Home.vue'
import TermsConditions from '../views/TermsConditions/TermsConditions.vue'
import Privacy from '../views/Privacy/Privacy.vue'
import NotFound from '../views/NotFound/NotFound.vue'

import Amige from '../views/Clients/Amige.vue'
import ZenitSaude from '../views/Clients/ZenitSaude.vue'

const routes = [
  { path: '/', name: 'redirect', redirect: '/pt', component: Home },
  { path: '/:lang/', name: 'Home', component: Home },
  { path: '/:lang/terms-conditions', name: 'TermsConditions', component: TermsConditions },
  { path: '/:lang/privacy', name: 'Privacy', component: Privacy },
  { path: '/:lang/:catchAll(.*)', component: NotFound },
  
  { path: '/:lang/amige', component: Amige },
  { path: '/:lang/zenit', component: ZenitSaude },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
