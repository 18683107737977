<template>
   <div class="main">
      <div class="home" id="home">
         <Header />

         <Slider />


         <!-- ABOUT US -->
         <section class="about-us">
            <div class="anc" id="about"></div>

            <div class="container">
               <div class="row align-items-center">
                  <div class="col-12 col-lg-6">
                     <h2 class="title-primary" data-aos="fade-up">{{getMyLanguage("home","about.title")}}</h2>
                     <p data-aos="fade-up">{{getMyLanguage("home","about.txt01")}}</p>
                     <p data-aos="fade-up">{{getMyLanguage("home","about.txt02")}}</p>
                     <p data-aos="fade-up">{{getMyLanguage("home","about.txt03")}}</p>
                  </div>

                  <div class="col-12 col-lg-6 no-tablet">
                     <img src="~@/assets/images/content/about-us.jpg" alt="Índice Avançado" data-aos="fade-up">
                  </div>
               </div>

               <div class="row mission-vision">
                  <div class="col-12 col-sm-6 col-lg-4">
                     <div class="box">
                        <h3 data-aos="fade-up">{{getMyLanguage("home","about.mission.title")}}</h3>
                        <img src="~@/assets/images/content/mission.jpg" data-aos="fade-up">
                     </div>
                     <div class="cont-box-about" data-aos="fade-up" v-html='getMyLanguage("home","about.mission.txt")'></div>
                  </div>

                  <div class="col-12 col-sm-6 col-lg-4">
                     <div class="box">
                        <h3 data-aos="fade-up">{{getMyLanguage("home","about.vision.title")}}</h3>
                        <img src="~@/assets/images/content/vision.jpg" data-aos="fade-up">
                     </div>
                     <div class="cont-box-about" data-aos="fade-up" v-html='getMyLanguage("home","about.vision.txt")'></div>
                  </div>

                  <div class="col-12 col-sm-6 col-lg-4">
                     <div class="box">
                        <h3 data-aos="fade-up">{{getMyLanguage("home","about.values.title")}}</h3>
                        <img src="~@/assets/images/content/values.jpg" data-aos="fade-up">
                     </div>
                     <div class="cont-box-about" data-aos="fade-up" v-html='getMyLanguage("home","about.values.txt")'></div>
                  </div>
               </div>
            </div>
         </section>


         <!-- SOLUTIONS -->
         <section class="solutions">
            <div class="anc" id="solutions"></div>

            <div class="container">
               <div class="row justify-content-center">
                  <div class="col-12 col-sm-8 col-lg-6">
                     <h2 data-aos="fade-up">{{getMyLanguage("home","solutions.title")}}</h2>
                     <p data-aos="fade-up">{{getMyLanguage("home","solutions.txt")}}</p>
                  </div>
               </div>

               <div class="row services">
                  <div class="col-12 col-sm-6 col-lg-4">
                     <div class="box" data-aos="zoom-in">
                        <div class="icon" data-aos="fade-up">
                        <img src="~@/assets/images/icons/programming.svg">
                        </div>

                        <h3 data-aos="fade-up">{{getMyLanguage("home","solutions.programming.title")}}</h3>
                        <p data-aos="fade-up">{{getMyLanguage("home","solutions.programming.txt")}}</p>
                     </div>
                  </div>

                  <div class="col-12 col-sm-6 col-lg-4">
                     <div class="box" data-aos="zoom-in">
                        <div class="icon" data-aos="fade-up">
                        <img src="~@/assets/images/icons/design.svg">
                        </div>

                        <h3 data-aos="fade-up">{{getMyLanguage("home","solutions.design.title")}}</h3>
                        <p data-aos="fade-up">{{getMyLanguage("home","solutions.design.txt")}}</p>
                     </div>
                  </div>
                  
                  <div class="col-12 col-sm-6 col-lg-4">
                     <div class="box" data-aos="zoom-in">
                        <div class="icon" data-aos="fade-up">
                        <img src="~@/assets/images/icons/marketing-digital.svg">
                        </div>

                        <h3 data-aos="fade-up">{{getMyLanguage("home","solutions.marketing-digital.title")}}</h3>
                        <p data-aos="fade-up">{{getMyLanguage("home","solutions.marketing-digital.txt")}}</p>
                     </div>
                  </div>
                  
                  <div class="col-12 col-sm-6 col-lg-4">
                     <div class="box" data-aos="zoom-in">
                        <div class="icon" data-aos="fade-up">
                        <img src="~@/assets/images/icons/cyber-security.svg">
                        </div>

                        <h3 data-aos="fade-up">{{getMyLanguage("home","solutions.cyber-security.title")}}</h3>
                        <p data-aos="fade-up">{{getMyLanguage("home","solutions.cyber-security.txt")}}</p>
                     </div>
                  </div>
                  
                  <div class="col-12 col-sm-6 col-lg-4">
                     <div class="box" data-aos="zoom-in">
                        <div class="icon" data-aos="fade-up">
                        <img src="~@/assets/images/icons/blockchain.svg">
                        </div>

                        <h3 data-aos="fade-up">{{getMyLanguage("home","solutions.blockchain.title")}}</h3>
                        <p data-aos="fade-up">{{getMyLanguage("home","solutions.blockchain.txt")}}</p>
                     </div>
                  </div>
                  
                  <div class="col-12 col-sm-6 col-lg-4">
                     <div class="box" data-aos="zoom-in">
                        <div class="icon" data-aos="fade-up">
                        <img src="~@/assets/images/icons/sites-ecommerces.svg">
                        </div>

                        <h3 data-aos="fade-up">{{getMyLanguage("home","solutions.site-ecommerce.title")}}</h3>
                        <p data-aos="fade-up">{{getMyLanguage("home","solutions.site-ecommerce.txt")}}</p>
                     </div>
                  </div>
                  
                  <div class="col-12 col-sm-6 col-lg-4">
                     <div class="box" data-aos="zoom-in">
                        <div class="icon" data-aos="fade-up">
                        <img src="~@/assets/images/icons/app.svg">
                        </div>

                        <h3 data-aos="fade-up">{{getMyLanguage("home","solutions.app.title")}}</h3>
                        <p data-aos="fade-up">{{getMyLanguage("home","solutions.app.txt")}}</p>
                     </div>
                  </div>
                  
                  <div class="col-12 col-sm-6 col-lg-4">
                     <div class="box" data-aos="zoom-in">
                        <div class="icon" data-aos="fade-up">
                        <img src="~@/assets/images/icons/infrastructure.svg">
                        </div>

                        <h3 data-aos="fade-up">{{getMyLanguage("home","solutions.infrastructure.title")}}</h3>
                        <p data-aos="fade-up">{{getMyLanguage("home","solutions.infrastructure.txt")}}</p>
                     </div>
                  </div>
                  
                  <div class="col-12 col-sm-6 col-lg-4">
                     <div class="box" data-aos="zoom-in">
                        <div class="icon" data-aos="fade-up">
                        <img src="~@/assets/images/icons/outsourcing.svg">
                        </div>

                        <h3 data-aos="fade-up">{{getMyLanguage("home","solutions.outsourcing.title")}}</h3>
                        <p data-aos="fade-up">{{getMyLanguage("home","solutions.outsourcing.txt")}}</p>
                     </div>
                  </div>
               </div>
            </div>
         </section>


         <!-- TEAM -->
         <section class="team" id="team">
            <div class="anc" id="team"></div>

            <div class="container">
               <div class="row">
                  <div class="col-12 col-lg-4 no-tablet">
                     <img src="~@/assets/images/content/team.jpg" data-aos="fade-up">
                  </div>

                  <div class="col-12 col-lg-8 content-team">
                     <h2 data-aos="fade-up">{{getMyLanguage("home","team.title")}}</h2>
                     <p data-aos="fade-up">{{getMyLanguage("home","team.txt")}}</p>
                     
                     <ul>
                        <li data-aos="fade-up" v-html='getMyLanguage("home","team.team01")'></li>
                        <li data-aos="fade-up" v-html='getMyLanguage("home","team.team02")'></li>
                        <li data-aos="fade-up" v-html='getMyLanguage("home","team.team03")'></li>
                        <li data-aos="fade-up" v-html='getMyLanguage("home","team.team04")'></li>
                        <li data-aos="fade-up" v-html='getMyLanguage("home","team.team05")'></li>
                        <li data-aos="fade-up" v-html='getMyLanguage("home","team.team06")'></li>
                        <li data-aos="fade-up" v-html='getMyLanguage("home","team.team07")'></li>
                     </ul>
                  </div>
               </div>
            </div>
         </section>

         <Footer />

      </div>
      <CookieConsent />
   </div>
</template>

<script>
   const $ = require('jquery')
   window.$ = $
   
   // window.onload = function() {

   //    // HOME > ABOUT US
   //    let aboutLnk = $('#nav-dne li .lnk-about')
   //    let aboutTop = $('section.about-us').offset().top
   //    let homeLnk = $('#nav-dne li .lnk-home')
   //    console.log(aboutTop)

   //    $(window).scroll(function () {
   //       if ($(this).scrollTop() > aboutTop) {
   //          $('#nav-dne li a').removeClass("active")
   //          aboutLnk.addClass("active");
   //          homeLnk.removeClass("active");
   //       } else {
   //          aboutLnk.removeClass("active");
   //          homeLnk.addClass("active");
   //       }
   //    });


   //    // SOLUTIONS
   //    let solutionsLnk = $('#nav-dne li .lnk-solutions')
   //    let solutionsTop = $('section.solutions').offset().top
   //    console.log(solutionsTop)
      
   //    $(window).scroll(function () {
   //       if ($(this).scrollTop() > solutionsTop) {
   //          $('#nav-dne li a').removeClass("active")
   //          solutionsLnk.addClass("active");
   //       } else {
   //          solutionsLnk.removeClass("active");
   //       }
   //    });


   //    // TEAM
   //    let teamLnk = $('#nav-dne li .lnk-team')
   //    let teamTop = $('section.team').offset().top
   //    console.log(teamTop)
      
   //    $(window).scroll(function () {
   //       if ($(this).scrollTop() > teamTop) {
   //          $('#nav-dne li a').removeClass("active")
   //          teamLnk.addClass("active");
   //       } else {
   //          teamLnk.removeClass("active");
   //       }
   //    });


   //    // CONTACT
   //    let contactLnk = $('#nav-dne li .lnk-contact')
   //    let contactTop = $('section.contact').offset().top
   //    console.log(contactTop)
      
   //    $(window).scroll(function () {
   //       if ($(this).scrollTop() > contactTop) {
   //          $('#nav-dne li a').removeClass("active")
   //          contactLnk.addClass("active");
   //       } else {
   //          contactLnk.removeClass("active");
   //       }
   //    });
   // };


   import Header from '@/components/Header.vue';
   import Slider from '@/components/Slider.vue';
   import Footer from '@/components/Footer.vue';
   import CookieConsent from '@/components/CookieConsent.vue';
   
   import getMyFavouriteLanguage from "@/services/language.js";


   export default {
      components: {
         Header,
         Slider,
         Footer,
         CookieConsent
      },
      data(){
         return{
            flagSelected: "en",
         }
      },
      async mounted(){
         this.flagUrl()
      },
      methods: {
         flagUrl(){
            const flagSelect = String(window.location.pathname).split("/")[1];
            this.flagSelected = flagSelect
         },

         getMyLanguage(group, code){
               return getMyFavouriteLanguage(group, code)
         }
      },
      
      created () {
         document.title = this.getMyLanguage("seo","title.home");
         document.getElementsByTagName('meta')["description"].content = this.getMyLanguage("seo","title.description.home");
         document.getElementsByTagName('meta')["twitter:title"].content = this.getMyLanguage("seo","title.description.home");
         document.getElementsByTagName('meta')["twitter:description"].content = this.getMyLanguage("seo","title.description.home");
         document.querySelector('meta[property="og:title"]').setAttribute("content", this.getMyLanguage("seo","title.home"));
         document.querySelector('meta[property="og:description"]').setAttribute("content", this.getMyLanguage("seo","title.description.home"));
      },
   }
</script>

<style>
   .home header{background: transparent !important;}
   .headerFixed .home header{background: #14151A !important;}

   @media(min-width: 1200px){
      .home .contact{
         margin-top: -130px;
      }
   }
</style>

<style src="./custom-home.scss" lang="scss" scoped />