<template>
    <div class="login">
        <!-- LOGIN -->
        <div class="sign-in">
            <div class="open-box-login" @click="loginActive = true"></div>
            <span class="photo"></span>
            <span class="no-logged">{{ getMyLanguage("login", "login.sign-in") }}</span>
        </div>

        <div class="box-login" v-if="loginActive">
            <div class="close-box-login" v-if="loginActive" @click="loginActive = false"></div>
            
            <h4 class="title-secondary">{{ getMyLanguage("login", "login.access-account") }}</h4>
            <div class="LoginOne">
                <input type="text" class="form formEmailLogin" :class="{error: error}" id="emailLogin" @keyup.enter="loginPlatform()" :placeholder='getMyLanguage("login","login.placeholder.login")' v-model="sign.username" autocomplete="off" required>
                
                <div class="enterPass">
                    <input type="password" class="form formPassLogin" :class="{error: error}" v-if="!showPass" @keyup.enter="loginPlatform()" :placeholder='getMyLanguage("login","login.placeholder.password")' v-model="sign.password" autocomplete="off" required>
                    <input type="text" class="form formPassLogin" :class="{error: error}" v-if="showPass" @keyup.enter="loginPlatform()" :placeholder='getMyLanguage("login","login.placeholder.password")' v-model="sign.password" autocomplete="off" required>

                    <img src="@/assets/images/icons/no-view.svg" class="eyePass" v-if="!showPass" @click="showPass = true" />
                    <img src="@/assets/images/icons/view.svg" class="eyePass" v-if="showPass" @click="showPass = false" />
                </div>

                <!-- <div class="btn-remember-password" @click="loginResetPass">{{ getMyLanguage("login", "login.remember-password") }}</div> -->

                <div class="btn-primary btn-disabled" v-if="sign.username.length <= 5 || sign.password.length <= 7">{{ getMyLanguage("login","login.login") }}</div>
                <div class="btn-primary" @click="loginPlatform()" v-if="!stepLogin.load && sign.username.length > 5 && sign.password.length > 7">{{ getMyLanguage("login","login.login") }}</div>
                <div class="btn-primary btn-loading" v-if="stepLogin.load"></div>
            </div>

            <!-- <div class="rememberPass rememberSuccess" v-if="!loginRemember && rememberSend">
                <p>{{getMyLanguage("login","login.remember-password.success")}}</p>
            </div>
            
            <div class="rememberPass rememberError" v-if="loginRemember && rememberError">
                <p>{{getMyLanguage("login","login.remember-password.error")}}</p>
            </div> -->
        </div>

        <div class="overlay" v-if="loginActive"></div>
    </div>
</template>


<script>
    const $ = require('jquery')
    window.$ = $

    import getMyFavouriteLanguage from "@/services/language.js";

    export default{
        data(){
            return{
                flagSelected: '',
                loginActive: false,
                stepLogin: false,
                sign: {
                    username: "",
                    password: ""
                },

                showPass: false,
                error: false
            }
        },

        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },

            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },

            loginPlatform(){
                this.error = true
            },

            openBoxLogin(){
                this.loginActive = true
            }
        },


         async mounted(){
            this.flagUrl();
        }
    }
</script>

<style src="@/assets/scss/_login.scss" lang="scss" scoped />