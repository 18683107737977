<template>
    <div class="main">
        <div class="privacy-cookies">
            <Header />

            <section class="privacy">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h2 class="title-primary" data-aos="fade-up">{{getMyLanguage("privacy","privacy.title")}}</h2>
                            <div v-html='getMyLanguage("privacy","privacy.txt")'></div>

                            <!-- <h4>Que dados pessoais são recolhidos e porquê</h4>
                            <h3>Cookies</h3>
                            <p>Se visitar a página de início de sessão, será configurado um cookie temporário para determinar se o seu navegador aceita cookies. Este cookie não contém dados pessoais e será eliminado ao fechar o seu navegador.</p>
                            <p>Ao iniciar a sessão, serão configurados alguns cookies para guardar a sua informação de sessão e as suas escolhas de visualização de ecrã. Os cookies de início de sessão duram até um ano. Se seleccionar “Lembrar-me”, a sua sessão irá persistir durante duas semanas. Ao terminar a sessão, os cookies de inicio de sessão serão removidos.</p>

                            <h3>Conteúdo incorporado de outros sites</h3>
                            <p>Este site pode recolher dados sobre si, usar cookies, incorporar rastreio feito por terceiros, monitorizar as suas interacções com o mesmo, incluindo registar as interacções com conteúdo incorporado se tiver uma conta e estiver com sessão iniciada nesse site.</p>

                            <h3>Dados analíticos</h3>
                            <p>Para utilizadores que se registem no nosso site (se algum), guardamos a informação pessoal fornecida no seu perfil de utilizador. Todos os utilizadores podem ver, editar, ou eliminar a sua informação pessoal a qualquer momento (com a excepção de não poderem alterar o nome de utilizador). Os administradores do site podem também ver e editar essa informação.</p>

                            <h3>Que direitos tem sobre os seus dados</h3>
                            <p>Se tiver uma conta neste site, pode pedir para receber um ficheiro de exportação com os dados pessoais guardados sobre si, incluindo qualquer dado pessoal que indicou. Também pode solicitar que os dados guardados sejam eliminados. Isto não inclui qualquer dado pessoal que seja obrigatório manter para fins administrativos, legais ou de segurança.</p> -->
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
        <CookieConsent />
    </div>
</template>


<script>
    import Header from '@/components/Header.vue';
    import Footer from '@/components/Footer.vue';
    import CookieConsent from '@/components/CookieConsent.vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    export default {
        components: {
            Header,
            Footer,
            CookieConsent
        },
        methods: {
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },
        },

        created () {
            document.title = this.getMyLanguage("seo","title.404");
            document.getElementsByTagName('meta')["description"].content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        },
    }
</script>

<style src="./custom-privacy.scss" lang="scss" scoped />