<template>
   <div class="banner">
      <div class="container">
         <div class="row">
            <div class="col-12 col-lg-4">
               <h1 v-html='getMyLanguage("banner","txt.01")'></h1>
            </div>
         </div>
      </div>

      <img src="~@/assets/images/content/banner.jpg">
   </div>
</template>

<script>
   import getMyFavouriteLanguage from "@/services/language.js";

   export default {
      data (){
         return{
            flagSelected: "en",
         }
      },
      async mounted (){
         this.flagUrl()
      },
      methods: {
         getMyLanguage(group, code){
            return getMyFavouriteLanguage(group, code)
         },

         flagUrl(){
            const flagSelect = String(window.location.pathname).split("/")[1];
            this.flagSelected = flagSelect
         },
      }
   }
</script>

<style src="@/assets/scss/_slider.scss" lang="scss" scoped />