<template>
    <div class="main">
        <div class="not-found">
            <Header />
            <section class="erro404">
                <div class="container">
                    <div class="row">
                        <div class="col-12 img-404">
                            <img src="~@/assets/images/icons/404.svg" alt="404">
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <CookieConsent />
    </div>
</template>


<script>
    import Header from '@/components/Header.vue';
    import CookieConsent from '@/components/CookieConsent.vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    export default {
        components: {
            Header,
            CookieConsent
        },
        methods: {
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },
        },

        created () {
            document.title = this.getMyLanguage("seo","title.404");
            document.getElementsByTagName('meta')["description"].content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        },
    }
</script>

<style src="./custom-404.scss" lang="scss" scoped />