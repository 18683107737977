<template>

   <section class="contact">
      <div class="anc" id="contact"></div>

      <div class="container">
         <div class="row align-items-center">
            <div class="col-12 col-lg-6">
               <h2 data-aos="fade-up">{{getMyLanguage("home","contact.title")}}</h2>
               
               <p class="email" data-aos="fade-up"><a href="mailto:suporte@indiceavancado.com"><span>suporte@indiceavancado.com</span></a></p>
               <p class="phone" data-aos="fade-up"><a href="tel:351225098964"><span>+351 225 098 964 <small>({{getMyLanguage("footer","landline")}})</small></span></a></p>
               <p class="address" data-aos="fade-up"><span>Av. Dr. Domingos Gonçalves de Sá, 435<br>Sala 25 - 4435-213 - Rio Tinto - Portugal</span></p>
               
               <p class="terms" data-aos="fade-up"><a :href='"/" + flagSelected + "/terms-conditions"'><span>{{getMyLanguage("footer","terms-conditions")}}</span></a></p>
               <p class="privacy" data-aos="fade-up"><a :href='"/" + flagSelected + "/privacy"'><span>{{getMyLanguage("footer","privacy")}}</span></a></p>
               <p class="book" data-aos="fade-up"><a href="https://www.livroreclamacoes.pt/Utilizador/AutenticacaoConsumidor" target="_blank">{{getMyLanguage("home","contact.complaint-book")}}</a></p>
            </div>

            <div class="col-12 col-lg-6">
               <img src="~@/assets/images/content/contact.jpg" data-aos="fade-up">
            </div>
         </div>
      </div>
   </section>

   <footer>
      <p>Índice Avançado LDA NIPC 516547550</p>
      <p>© 2022 . {{getMyLanguage("footer","copyright")}}</p>
   </footer>

</template>


<script>
   import getMyFavouriteLanguage from "@/services/language.js";

   export default {
      data (){
         return {
            flagSelected: "en"
         }
      },

      async mounted(){
         this.flagUrl()
      },

      methods: {
         flagUrl(){
            const flagSelect = String(window.location.pathname).split("/")[1];
            this.flagSelected = flagSelect
         },

         getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },
      }
   }
</script>

<style src="@/assets/scss/_footer.scss" lang="scss" scoped />