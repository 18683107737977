<template>
    <div class="main">
        <div class="terms">
            <Header />

            <section class="terms-conditions">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h2 class="title-primary" data-aos="fade-up">{{getMyLanguage("terms","terms.title")}}</h2>

                            <div v-html='getMyLanguage("terms","terms.txt01")'></div>
                            <div v-html='getMyLanguage("terms","terms.txt02")'></div>
                            <div v-html='getMyLanguage("terms","terms.txt03")'></div>
                            <div v-html='getMyLanguage("terms","terms.txt04")'></div>
                            <div v-html='getMyLanguage("terms","terms.txt05")'></div>
                            <div v-html='getMyLanguage("terms","terms.txt06")'></div>
                            <div v-html='getMyLanguage("terms","terms.txt07")'></div>

                            <!-- <p>As seguintes cáusulas são os termos e condições gerais que estão associados a todas as prestações de serviço da Índice Avançado, adicionais às condições de prestação de serviço específicas da cada contrato firmado entre a Índice Avançado e os clientes.</p>
                            <p>Assim, o cliente Índice Avançado reconhece a existência destes Termos e Condições e é obrigado, dentro dos limites legais e em vigor na Lei Portuguesa, a seguir estas condições, para efeitos da prestação de qualquer serviço da Índice Avançado.</p>
                            <p>Condições de prestação de serviço adiante designada por Índice Avançado Lda e o cliente:</p>

                            <h4>Cláusula I:</h4>
                            <h3>1) Descrições Gerais:</h3>
                            <p>Índice Avançado, Soluções em Tecnologia</p>
                            <p>1.1) A Índice Avançado, presta serviços para empresas e/ou particulares que pretendam ter um website online, loja virtual, aplicação de telemóvel ou outros, gerindo os seus conteúdos.</p>
                            <p>1.2) A Índice Avançado caracteriza-se por oferecer através de sub-produtos, incluídos nos contratos efectuados com os clientes, os vários serviços ao cliente que podem estar relacionados com a normal prestação de serviço.</p>
                            <p>Toda a parte relacionada com Gestores de conteúdos, Backoffices, Anuidades, Dominios, SSL, Ips, Servidores de e-mails, etc. e helpdesk técnico para resolução de problemas técnicos derivados exclusivamente de bugs com o alojamento.</p>

                            <h3>2) Domínio:</h3>
                            <p>2.1) O serviço de domínio consiste no registo de um domínio da World Wide Web ao cliente, desde que disponível para registo</p>
                            <p>2.2) O registo de um domínio é um serviço que é fornecido por entidades externas à Índice Avançado.</p>
                            <p>2.3) As tipologias de domínios (TLD’s) incluídos nos serviços são as designadas por .com / .net / .org. e outras, de acordo com as disponibilidades momentâneas e que são comunicadas quer verbalmente ao cliente.</p>
                            <p>2.4) As tipologias de domínios (TLD’s) tais como .pt / .com.pt / .com / .eu são possíveis de serem registadas, mediante a liquidação da respectiva sobretaxa, cobrada pelas entidades externas que gerem este serviço, a serem cobradas ao cliente conforme os valores comunicados ao cliente e de forma verbal.</p>
                            <p>2.5) Interrupções ocasionais e/ou temporárias, raras, mas que poderão ocorrer, sendo devidas a falhas do software e hardware, da responsabilidade das entidades externas que gerem as tipologias de domínio (TLD’s), não poderão ser imputáveis à Índice Avançado.</p>

                            <h3>3) Alojamento Web:</h3>
                            <p>3.1) O serviço de alojamento Web consiste no alojamento do sítio do cliente, num servidor web, de modo a permitir a qualquer utilizador de internet, que tenha acesso a um computador com Web Browser Cliente e ligação à Internet, o acesso a esse sítio, de forma permanente.</p>
                            <p>3.2) O alojamento é profissional e é partilhado. O sítio do cliente fica alojado num servidor que partilhará os seus recursos com os sítios de outros clientes e de acordo com as normas do presente contrato. A utilização dos recursos do servidor obedece a esse príncipio de partilha.</p>
                            <p>3.3) Interrupções ocasionais e/ou temporárias, poderão ocorrer. Exemplos de situações que as podem causar, são actualizações e configurações de software do servidor, falhas do hardware ou software do servidor.</p>
                            <p>3.4) A reposição do serviço ocorrerá no menor espaço de tempo possível e necessário para a resolução do problema que o tenha motivado. Na data da publicação destas condições gerais, o uptime médio dos servidores da nossa empresa é superior a 99,9%.</p>
                            <p>3.5) O Sítio do cliente é composto por um conjunto de páginas Web, com programação html e/ou php e/ou perl, com ou sem suporte MySQL, que permite a disponibilização de informação ao público, num modo estático ou dinâmico, com o objectivo de informar, fazer publicidade, divulgar e comercializar os serviços e/ou produtos do cliente.</p>
                            <p>3.6) O serviço prestado inclui o alojamento de contas de e-mail. O servidor a utilizar pelo cliente suportará os protocolos POP3 e IMAP, permitindo que o correio enviado para essas contas de e-mail seja gravado no mesmo para que seja consultado pelo cliente através dos diversos programas de Webmail disponíveis. O cliente terá a possibilidade de efectuar Downloads do seu correio para o seu computador, desde que disponha do Software necessário, tais como clientes de e-mail.</p>

                            <h3>4) Índice Avançado site dinâmico – com base em CMS (Content Management System):</h3>
                            <p>4.1) O serviço de site dinâmico consiste num plano criado com base em CMS, de acordo com as condições particulares contratadas entre as partes.</p>
                            <p>4.2) A adesão a este serviço permite ao cliente manter um (1) site web ou sítio de internet enquanto o serviço estiver activo e o contrato fôr válido.</p>
                            <p>4.3) O site dinâmico pressupõe formação ao cliente, por parte da nossa empresa, de acordo com as condições particulares para que este possa saber utilizar o software de forma correcta e responsável.</p>
                            <p>4.3.1) Esta formação – a ser prestada nas instalações do cliente, desde que as mesmas se localizem no concelho de Porto – ou por outra via – por e-mail, telefone, ou outros meios, será prestada após o website estar pronto e sujeito à confirmação e validação do presente contrato.</p>
                            <p>4.3.2) Entende-se como confirmação e validação do contrato, a recepção do formulário devidamente preenchido e assinado pelo cliente, não obstante a não satisfação do pagamento inicial do mesmo ou envio de elementos necessários para a execução do mesmo, definido no presente contrato.</p>
                            <p>4.4) O site dinâmico inclui a criação de uma (1) página de design ao cliente.</p>
                            <p>4.4.1) A criação das páginas será sempre baseada nas limitações do software e apenas após a recepção de todos os elementos a fornecer pelo cliente, devendo os mesmos serem fonecidos nas especificações solicitadas e em perfeitas condições de utilização.</p>
                            <p>4.4.2) Não serão considerados elementos que possam, de alguma forma, ter implícitos, direitos de autor ou copyright.</p>

                            <h3>5) Outras prestações de serviço relacionadas com Marketing, Comunicação, Imagem e Design:</h3>
                            <p>5.1) Os serviços prestados regem-se, antes de mais, pela Lei Portuguesa e de acordo com as condições particulares contratadas entre as partes.</p>
                            <p>5.2) A adesão à prestação deste(s) serviço(s) permite ao cliente beneficiar dos serviços de consultoria e que têm como resultado um trabalho final traduzido num ou mais ficheiros, formatados e fechados para que o cliente os possa utilizar, de forma limitada e dentro dos termos acordados.</p>
                            <p>5.3) O cliente, obriga-se a utilizar os ficheiros fornecidos pela Índice Avançado para o objectivo designado de forma contratual e de forma correcta e responsável, por forma a que não prejudique os direitos de autor do trabalho, nem indo contra a lei portuguesa de direitos de autor.</p>
                            <p>5.3.1) Desta forma o cliente obriga-se a não fornecer os ficheiros adquiridos a terceiros nem a os modificar sob qualquer forma, pois estrá a incorrer em abuso sobre os direitos autoriais. Caso o cliewnte necessite que os ficheiros tenham de ser alterados e na impossibilidade da Índice Avançado de poder executar os trabalhos, poderá pedir uma licença especial para o fazer, mediante uma taxa que não será nunca inferior a 30% do valor total do trabalho específico adjudicado, num montante mínimo de 1000,00€, acrescidos de impostos à taxa legal em vigor, nomeadamente o IVA.</p>
                            <p>5.4) Todos os serviços prestados que não pressuponham a entrega de ficheiros serão sempre liquidados em 100% na adjudicação dos serviços.</p>

                            <h4>Claúsula II: Regras relativas à utilização do serviço:</h4>
                            <p>O cliente obriga-se:</p>
                            <p>1) A garantir a segurança do acesso a todo o tipo de documentação confidencial e a toda a informação decortrente de todos os processos, nomeadamente, e no que diz respeito à implementação de serviços relacionados com desenvolvimento Web e Tecnológico que inclua alojamento, ao painel de controlo e ao seu espaço virtual, assumindo o seguinte:</p>
                            <p>1.1) Impedir o acesso de terceiros a passwords e ficheiros que lhe são confiados.</p>
                            <p>1.2) Utilização mínima de acessos FTP, por transmitirem dados e passwords sem encriptação.</p>
                            <p>1.3) Alterar com frequência e periodicamente as passwords das contas FTP e de contas fornecidas pela Índice Avançado, desde que em coerência com o contrato.</p>
                            <p>1.4) Utilização de programas que não tenham falhas de segurança.</p>
                            <p>2) Fazer cópias de segurança da informação, programas e bases de dados alojados no nosso servidor, com uma periodicidade adequada às actualizações dessa informação, de modo a prevenir eventuais perdas de dados, independentemente da causa das mesmas. O painel de controlo, permite ao cliente a possibilidade de efectuar cópias de segurança de forma rápida e facilitada.</p>
                            <p>3) A não violar qualquer lei em vigor na Ordem Jurídica Portuguesa ou noutra aplicável, de acordo com as normas do Direito Internacional Privado, incluíndo direitos de autor e de propriedade industrial. A prática de ilícitos criminais, tais como publicação ou transmissão de pornografia infantil, será denunciada aos órgãos de polícia criminal competentes. O conceito de transmissão mencionado inclui, para além do conceito jurídico, a transmissão de e para o servidor.</p>
                            <p>4) A não enviar SPAM- informação de índole comercial não solicitada e enviada para mais que um destinatário, incluindo junk mail, chain letters e pyramid schemes e/ou qualquer mensagem de SPAM. A não associar qualquer conta de e-mail alojada nos nossos servidores a qualquer mensagem SPAM. Estas obrigações também se aplicam no caso das mailing lists. As mailing Listas apenas poderão ser enviadas por sistemas técnicos específicos comercializados à parte e não fazem parte de um sistema de alojamento base. O conceito de SPAM utilizado está definido no website spamhaus.org.</p>
                            <p>5) A não fazer publicidade a qualquer domínio alojado nos nossos servidores violando as normas de boa conduta, estabelecidas na internet, incluíndo as normas que regulamentam a utilização do IRC e do USENET.</p>
                            <p>6) A não violar a segurança ou prejudicar o funcionamento de qualquer servidor, sistema informático, de telecomunicações ou de transmissão de dados entre esses sistemas, incluindo qualquer tentativa ou processo informático que contenha código que possa ser utilizado com esse fim incluindo também acções de sniffing, pishing ou outros.</p>
                            <p>7) A não publicar qualquer informação associada a Crackers, Hackers, Warez ou inserir links para sítios que publicam essa informação.</p>
                            <p>8) A não alojar qualquer servidor de IRC, nem utilizar quaisquer recursos do servidor para participar em quaisquer acções que possam ser julgadas ilícitas ou má conduta de IRC. Esta proibição é extensível a software de chat online, salvo em situações em se sejam previamente aprovadas pela Índice Avançado.</p>
                            <p>9) A não transmitir os recursos disponíveis a terceiros, especialmente se anónimos.</p>
                            <p>10) A não publicar ou transmitir – de e para o servidor – quaisquer conteúdos pornográficos.</p>

                            <h4>Claúsula III: Suspensão e Cancelamento do contrato:</h4>
                            <p>1) Índice Avançado poderá suspender ou cancelar o contrato e ou contas de alojamento web, caso o cliente viole qualquer uma das obrigações estabelecidas na Cláusula II das Condições e Termos Gerais ou caso a utilização não se enquadre no tipo de utilização previsto na Cláusula II, não tendo o cliente direito a qualquer reembolso ou indemnização, pelos valores contratados, liquidados ou não.</p>
                            <p>2) A utilização da suspensão ou cancelamento de conta, serão assumidos por Índice Avançado, tomando em consideração a gravidade da infracção cometida, bem como os danos causados a terceiros e a culpa do cliente.</p>

                            <h4>Claúsula IV: Duração e renovação do Contrato:</h4>
                            <p>1) Salvo informação em contrário – que deverá constar do contrato, sendo indicado a data de início e do seu fim – o mesmo é feito por um período mínimo de um (1) ano, podendo ser também de dois (2), três (3), quatro (4) ou mais anos, sendo renovado por iguais e sucessivos períodos de tempo.</p>
                            <p>2) Qualquer uma das partes poderá denunciar o contrato desde que notifique a outra por correio registado com 30 dias de antecedência à data do fim do prazo contratado.</p>
                            <p>3) O cliente tem direito ao período de reflexão de 15 dias, pelo que se pretender desisitir do mesmo, poderá fazê-lo nesse período, através de comunicação por escrito via CTT por carta registada e com aviso de recepção para a morada da empresa Índice Avançado.</p>
                            <p>4) para efeitos de data de comunicação de desistência conta a data afixada em carimbo dos CTT.</p>

                            <h4>Claúsula V: Preço e valor do Contrato:</h4>
                            <p>1) O preço dos serviços a serem disponibilizados, alvo do presente contrato, serão os mencionados no mesmo, acrescidos das taxas aplicáveis, nomeadamente o IVA.</p>
                            <p>2) Na renovação do contrato, aplicar-se-ão os preços em vigôr.</p>
                            <p>4) O cliente compromete-se a liquidar as quantias que estão acordadas neste contrato, de forma atempada.</p>
                            <p>5) A Índice Avançado rembolsará os valores já liquidados ao cliente, em caso de desistência, dentro do período de reflexão, deduzidos dos custos associados à criação do serviço já efectuado, à data da comunicação da desistência.</p>
                            <p>6) Em caso de desistência e caso os custos do serviço excedam os montantes já liquidados pelo cliente, este compromete-se a liquidar os valores remanescentes até perfazer o integral pagamento à Uebyou desses valores, num período nunca inferior a 8 dias após a data de comunicação da desistência. Neste caso a Índice Avançado emitirá um documento com a descrição dos serviços efectuados bem como do valor associado aos mesmos, bem como correspondente factura e recibo.</p>

                            <h4>Claúsula VI: Utilização Abusiva:</h4>
                            <p>1) O cliente não poderá utilizar qualquer programa, ficheiro(s), script ou processo que monopolize os recursos do servidor ou cause erros no seu funcionamento.</p>
                            <p>2) A Índice Avançado poderá terminar, desactivar, remover e, em último caso, apagar esse programa, ficheiro(s) script ou processo, de modo a garantir o bom funcionamento do servidor e a distribuição equitativa dos recursos pelos respectivos utilizadores.</p>

                            <h4>Claúsula VII: Assistência Técnica:</h4>
                            <p>A Índice Avançado, prestará assistência técnica gratuita que se mostre necessária ao correcto e bom desempenho dos serviços contratados, conforme se indica:</p>
                            <p>1) O objecto da assistência técnica terá que ser qualquer avaria ou erro no funcionamento do servidor ou qualquer pedido de ajuda relativo de utilização do painel de controlo (cpanel).</p>
                            <p>2) A assistência técnica solicitada responderá no prazo máximo de 48 horas sobre a data e hora da recepção do respectivo pedido por parte do cliente.</p>
                            <p>3) O pedido de assistência por parte do cliente deverá ser sempre efectuado através do envio de um e-mail para suporte@indiceavancado.com.</p>
                            <p>4) A Índice Avançado não prestará assistência técnica gratuita à utilização de software open source pré-instalado, exceptuando qualquer problema relativo à instalação dos serviços contratados. Também não prestará assistência técnica gratuita a qualquer programa instalado pelo cliente.</p>

                            <h4>Claúsula VIII: Incumprimento e carácter de título executivo:</h4>
                            <p>1) A falta de pagamento atempado de qualquer uma das prestações ou valores indicados neste contrato, implica o imediato vencimento das restantes e, bem assim, o vencimento de juros à taxa legal e comissões de gestão de contrato, contados sobre o capital em débito até ao seu integral pagamento (mín. 25 € taxa comissão, acrescida de demais impostos em vigôr).</p>
                            <p>2) As partes atribuem a este documento a força de título executivo, pelo que, o mesmo, poderá servir de base a uma eventual execução no caso de se verificar o incumprimento de qualquer das cláusulas deste contrato, por parte do cliente.</p>

                            <h4>Claúsula IX: Notificações:</h4>
                            <p>1) Qualquer notificação entre as partes terá que ter a forma escrita e deverá ser enviado por e-mail ou carta registada.</p>
                            <p>2) Para efeitos do que se indica no número anterior, informam-se os actuais contactos da Índice Avançado:</p>
                            <p>&nbsp;</p>
                            <ul>
                                <li>Serviços de Suporte: suporte@indiceavancado.com</li>
                                <li>Serviços Comerciais: +351 225 098 964</li>
                            </ul>

                            <p>3) Para efeitos do mencionado nesta cláusula e no que a tudo respeite ao envio de comunicações escritas, os elementos dos clientes serão os que constam dos contratos por eles preenchidos e assinados. Havendo alteração a esses elementos o cliente deverá, no mais curto espaço de tempo, comunicar as mesmas por escrito a Índice Avançado, sob pena de esta não poder cumprir com o que se estipula nesta cláusula.</p>
                            <p>4) Havendo qualquer incumprimento, por parte da Índice Avançado, ao estipulado no presente contrato, pelos motivos indicados em 3.1 desta cláusula, não lhe poderão ser imputadas quaisquer responsabilidades, pelos prejuízos que por tal facto possam advir para o cliente.</p>
                            <p>5) Para as questões emergentes do presente contrato, é competente o foro da Comarca de Porto, com expressa renúncia a qualquer outro.</p>
                            <p>6) As partes rescidem do reconhecimento notarial das assinaturas deste contrato.</p>

                            <h4>Claúsula X: Política de Privacidade de dados:</h4>
                            <p>1) O cliente aceita desde já todas as comunicações entre as partes, e por parte da Índice Avançado Lda. poderá enviar comunicações comerciais e contratuais e informações sobre notícias, alertas e comunicações de Marketing.</p>
                            <p>2) O cliente aceita que as comunicações da parte da Índice Avançado possas ser efetivada por e-mail, SMS, Telefone e outros meios escritos.</p>
                            <p>3) A Índice Avançado Lda., informa desde já que todos os dados fornecidos pelo cliente e mantidos pela Índice Avançado estão salvaguardados nas instalações da Índice Avançado que para efeitos de RGPD possui instalações comerciais na Av. Dr. Domingos Gonçalves de Sá, 435, Sala 25 (4435-213), Rio Tinto - Portugal. A Índice Avançado informa que os dados contratuais, nomeadamente nome do responsável pela empresa, NIPC e NIF, moradas e e-mails associados, bem como outros dados que possam identificar o cliente se encontram guardados, por motivos de execução do serviço, tanto na empresa fornecedora da infraestrutura de domínios como também pela empresa fornecedora da infraestrutura de Data Center. A Índice Avançado permite ao cliente, caso deixe de o ser, o acesso, a retificação e/ou o direito ao esquecimento dos dados. Estes dados, caso o cliente deixe de possuir serviços por parte da Índice Avançado serão mantidos pela Índice Avançado, durante um prazo de 50 anos e poderão ser actualizados sem o consentimento por parte da entidade que já foi cliente.</p> -->
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
        <CookieConsent />
    </div>
</template>


<script>
    import Header from '@/components/Header.vue';
    import Footer from '@/components/Footer.vue';
    import CookieConsent from '@/components/CookieConsent.vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    export default {
        components: {
            Header,
            Footer,
            CookieConsent
        },
        methods: {
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },
        },

        created () {
            document.title = this.getMyLanguage("seo","title.404");
            document.getElementsByTagName('meta')["description"].content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        },
    }
</script>

<style src="./custom-terms-conditions.scss" lang="scss" scoped />