<template>
    <div class="select-language">
        <div class="selected-lang">
            <div class="close-select" v-if="selectStatus" @click="selectStatus = false"></div>
            <div class="flag" @click="selectStatus = true">
                <img :src='"/" + flagSelected + ".png"'>
            </div>
        </div>
        <div class="flags-language" v-if="selectStatus">
            <div class="list-languages">
                <div class="flag" v-for="(langs,index) in dataLanguages" :key="index" @click="changeLanguage(langs.code)">
                    <img :src='"/" + langs.code + ".png"'>
                    <span>{{langs.name}}</span>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    export default{
        data: function () {
            return {
                flagSelected: '',
                selectStatus: false,
                flagShow: false,
                dataLanguages: [
                    {
                        code: "en",
                        name: "English"
                    },
                    {
                        code: "es",
                        name: "Español"
                    },
                    {
                        code: "pt",
                        name: "Português"
                    }
                ],
            }
        },
        methods: {
            changeLanguage(language){
                let url = String(window.location.pathname).split("/");
                url[1] = language;

                window.location.href = `${url.join('/')}`;
            }
        },
        async mounted () {
            this.flagSelected = String(window.location.pathname).split("/")[1];
        }
    }
</script>


<style src="@/assets/scss/_selectLanguage.scss" lang="scss" scoped />