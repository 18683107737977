import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Toaster from '@meforma/vue-toaster'
import VueMask from '@devindex/vue-mask';
import AOS from 'aos'
import 'aos/dist/aos.css'

import moment from 'moment-timezone'
moment.tz.setDefault('Europe/Lisbon')

createApp(App)
.use(store)
.use(router)
.use(Toaster)
.use(VueMask)
.mount('#app')
AOS.init()
