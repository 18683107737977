<template>
    <div>
        <ul id="nav-dne">
            <img src="~@/assets/images/header/logo-indice-avancado.svg" class="logo">
            <div class="closeMenuMobile"></div>
            
            <li><a :href='"/" + flagSelected + "/#home"' class="lnk-home">{{getMyLanguage("menu","menu.home")}}</a></li>
            <li><a :href='"/" + flagSelected + "/#about"' class="lnk-about">{{getMyLanguage("menu","menu.about")}}</a></li>
            <li><a :href='"/" + flagSelected + "/#solutions"' class="lnk-solutions">{{getMyLanguage("menu","menu.solutions")}}</a></li>
            <li><a :href='"/" + flagSelected + "/#team"' class="lnk-team">{{getMyLanguage("menu","menu.team")}}</a></li>
            <li><a :href='"/" + flagSelected + "/#contact"' class="lnk-contact">{{getMyLanguage("menu","menu.contact")}}</a></li>
        </ul>
        

        <div class="openMenuMobile"></div>
    </div>
</template>


<script>
    const $ = require('jquery')
    window.$ = $

    $(document).on('click', '.openMenuMobile', function(){
        $('#menuPlatformMobile').addClass('menuMobileOpened');
    })

    $(document).on('click', '.closeMenuMobile', function(){
        $('#menuPlatformMobile').removeClass('menuMobileOpened');
    })

    $(document).on('click', '.menuMobile a', function(){
        $('#menuPlatformMobile').removeClass('menuMobileOpened');
    })

    import getMyFavouriteLanguage from "@/services/language.js";

    export default{
        data(){
            return {
                lnkPurchase: "#dne-token",
                showContent: false,
                flagSelected: {},
            }
        },
        async mounted(){
            this.flagUrl()
        },
        methods: {
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },

            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
        }
    }
</script>